<template>
  <BCard title="결제">
    <TagbyList
      v-model="currentPage"
      :items="payList"
      :fields="payListFields"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(min_idx)="data">
        <div
          class="text-primary"
          style="cursor: pointer"
          @click="data.toggleDetails"
        >
          {{ data.value }} <FeatherIcon :icon="data.detailsShowing ?'ChevronUpIcon' : 'ChevronDownIcon'" />
        </div>
      </template>

      <template #row-details="data">
        <TagbyList
          :fields="subPayFields"
          :items="data.item.pay_list"
        >
          <template #cell(total_amount)="subData">
            {{ resolveMoney(subData.item.amount + subData.item.vat) }}
          </template>

          <template #cell(state)="subData">
            {{ resolveState(subData.value) }}
          </template>

          <template #cell(created_at)="subData">
            <TagbyDatetimeColumn :value="subData.value" />
          </template>
        </TagbyList>
      </template>

      <template #cell(total_amount)="data">
        {{ resolveMoney(data.value) }}
      </template>

      <template #cell(target_type)="data">
        <div
          v-if="data.value === 'REWARD'"
        >
          <ComptIdxColumn :campaignIdx="data.item.target_idx" /> 캠페인 리워드
        </div>
        <div
          v-else-if="data.value === 'SUBS'"
        >
          정기구독권 ({{ data.item.target_idx }})
        </div>
        <div
          v-else-if="data.value === 'PAUG'"
        >
          <ComptIdxColumn :campaignIdx="data.item.paug_campaign_idx" /> 캠페인 건별결제 ({{ data.item.target_idx }})
        </div>
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            text="다른카드로 재결제"
            :disabled="data.item.total_amount === 0"
            @click="turnOnRepaySidebar(data.item.pid)"
          />

          <TagbyTableActionItem
            text="결제 취소"
            :disabled="data.item.total_amount === 0"
            @click="turnOnCancelModal(data.item.pid)"
          />
        </TagbyTableAction>
      </template>
    </TagbyList>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
} from 'bootstrap-vue'
import { usePayList, useRepay, useCancelPay } from '../viewModel'
import resolveValue from '@/utils/resolveValue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import ComptIdxColumn from '@/components/ComptIdxColumn.vue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'

export default defineComponent({
  components: {
    BCard,
    TagbyList,
    TagbyDatetimeColumn,
    ComptIdxColumn,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const {
      totalRows,
      perPage,
      currentPage,
      isBusy,

      payList,
      fetchPayList,
      changePage,
    } = usePayList()
    fetchPayList()

    const { resolveDateTime, resolveMoney } = resolveValue()
    const resolveState = stateValue => {
      if (stateValue === 'SUCCESS') {
        return '성공'
      }
      if (stateValue === 'FAIL') {
        return '실패'
      }
      if (stateValue === 'REGISTER') {
        return '이탈'
      }
      if (stateValue === 'CANCEL') {
        return '취소'
      }
      return '알 수 없음'
    }

    const payListFields = [
      { key: 'min_idx', label: 'idx' },
      { key: 'billing_idx', label: 'billing_idx' },
      { key: 'pay_type', label: '결제수단' },
      { key: 'target_type', label: '결제타겟' },
      { key: 'total_amount', label: '결제총액' },
      { key: 'created_at', label: '결제일시' },
      { key: 'actions', label: 'actions' },
    ]

    const subPayFields = [
      { key: 'idx', label: 'idx' },
      { key: 'description', label: '내용' },
      { key: 'total_amount', label: '결제총액' },
      { key: 'state', label: '상태' },
      { key: 'created_at', label: '결제일시' },
    ]

    const {
      turnOnSidebar: turnOnRepaySidebar,
    } = useRepay()

    const {
      turnOnModal: turnOnCancelModal,
    } = useCancelPay()

    return {
      totalRows,
      perPage,
      currentPage,
      isBusy,

      payListFields,
      payList,
      fetchPayList,
      changePage,
      resolveDateTime,
      resolveMoney,
      resolveState,

      subPayFields,
      turnOnRepaySidebar,
      turnOnCancelModal,
    }
  },
})
</script>
