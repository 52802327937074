<template>
  <TagbyDangerModal
    :visible="visibleRefundSubscriptionModal"
    title="구독권 환불"
    content="선택한 구독권을 환불처리 하시겠습니까"
    @ok="refundSubs"
    @hidden="turnOffModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import { useRefundSubscription } from '../viewModel'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    return {
      ...useRefundSubscription(),
    }
  },
})
</script>
