<template>
  <TagbySidebarWithButton
    title="제안횟숫 수정"
    :visible="visibleUpdateSuggestionCountSidebar"
    :isSaving="isSaving"
    :isValid="true"
    @ok="updateSuggestionCount"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
  >
    <TagbyInputRow label="남은 제안 횟수">
      <BFormInput v-model="suggestionCount" />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import {
  useUpdateSuggestionCount,
} from '../viewModel'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
  },
  setup() {
    const {
      visibleUpdateSuggestionCountSidebar,
      isSaving,
      suggestionCount,
      turnOffSidebar,
      updateSuggestionCount,
    } = useUpdateSuggestionCount()

    return {
      visibleUpdateSuggestionCountSidebar,
      isSaving,
      suggestionCount,
      turnOffSidebar,
      updateSuggestionCount,
    }
  },
})
</script>
