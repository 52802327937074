<template>
  <TagbyDangerModal
    :visible="visibleRefundPaugModal"
    title="건별결제 환불"
    content="건별결제 환불은 캠페인 진행단계에 영향을 주지 않습니다. 환불 완료 후, 캠페인 단계 변경이 필요할경우 개발팀에 문의 주세요"
    @ok="refundPaug"
    @hidden="turnOffModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import { useRefundPaug } from '../viewModel'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    return {
      ...useRefundPaug(),
    }
  },
})
</script>
