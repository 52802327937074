<template>
  <TagbySidebarWithButton
    :visible="visibleRepaySidebar"
    :isSaving="isSaving"
    :isValid="isValid"
    @ok="repay"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <div
      v-for="billing in activeBillingList"
      :key="billing.idx"
      class="mb-1"
    >
      <BCard
        :border-variant="billing.idx === selectedCard ? 'primary': 'secondary'"
        style="cursor: pointer"
        @click="selectCard(billing.idx)"
      >
        <div
          class="d-flex align-items-center"
        >
          <div class="mr-1">
            {{ billing.card_name }}
          </div>
          <div class="mr-1">
            [idx: {{ billing.idx }}]
          </div>
          <div
            v-if="billing.present"
            class="mr-1"
          >
            <BBadge variant="primary">
              대표
            </BBadge>
          </div>
        </div>
      </BCard>
    </div>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BBadge,
  BCard,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import {
  useRepay,
} from '../viewModel'

export default defineComponent({
  components: {
    BBadge,
    BCard,
    TagbySidebarWithButton,
  },
  setup() {
    const {
      visibleRepaySidebar,
      isSaving,
      isValid,
      repay,
      turnOffSidebar,
      selectCard,
      activeBillingList,
      selectedCard,
    } = useRepay()

    return {
      visibleRepaySidebar,
      isSaving,
      isValid,
      repay,
      turnOffSidebar,
      selectCard,
      activeBillingList,
      selectedCard,
    }
  },
})
</script>
