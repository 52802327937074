<template>
  <b-card title="캠페인">
    <b-tabs>
      <b-tab title="진행 중인 캠페인">
        <advertiser-campaign-table
          :campaigns="campaigns.filter(c => !['PROGRESSED', 'PENDING', 'WRITING'].includes(c.step) && c.state === 'ACTIVE')"
        />
      </b-tab>
      <b-tab title="종료된 캠페인">
        <advertiser-campaign-table
          :campaigns="campaigns.filter(c => c.step === 'PROGRESSED' && c.state === 'ACTIVE')"
        />
      </b-tab>
      <b-tab title="보류 중인 캠페인">
        <advertiser-campaign-table
          :campaigns="campaigns.filter(c => c.step === 'PENDING' && c.state === 'ACTIVE')"
        />
      </b-tab>
      <b-tab title="작성 중인 캠페인">
        <advertiser-campaign-table :campaigns="campaigns.filter(c => c.state === 'WRITING')" />
      </b-tab>
      <b-tab title="삭제된 캠페인">
        <advertiser-campaign-table :campaigns="campaigns.filter(c => c.state === 'INACTIVE')" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'

import AdvertiserCampaignTable from './AdvertiserCampaignTable.vue'

export default defineComponent({
  props: {
    campaigns: Array,
  },

  components: {
    BCard,
    BTabs,
    BTab,
    AdvertiserCampaignTable,
  },

  setup(props) {
    // const writingCampaignList = props.campaigns.filter(c => c.state === 'WRITING')

    return {
      // writingCampaignList,
    }
  },
})
</script>

<style>

</style>
