<template>
  <TagbyDangerModal
    :visible="visibleCancelPayModal"
    title="결제 취소"
    @ok="cancelPay"
    @hidden="turnOffModal"
  >
    <div>
      결제만 취소되고 구독권의 취소나 리워드의 취소 처리와 같은 추가적인 플로우를 실행하지 않으니 주의해서 사용이 필요합니다.
    </div>

  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import {
  useCancelPay,
} from '../viewModel'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    const {
      visibleCancelPayModal,
      cancelPay,
      turnOffModal,
    } = useCancelPay()

    return {
      visibleCancelPayModal,
      cancelPay,
      turnOffModal,
    }
  },
})
</script>
