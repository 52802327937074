<template>
  <b-table
    :items="campaigns"
    :fields="tableColumns"
    primary-key="idx"
    show-empty
    responsive
    small
  >
    <template #cell(idx)="data">
      <b-link
        v-if="['COMPT', 'SIMPLE_RECRUIT', 'PURCHASE_REVIEW'].includes(data.item.type)"
        :to="{ name: 'campaign-compt-active-detail', params: { idx: data.item.idx } }"
        class="font-weight-bold d-block text-nowrap"
      >
        {{ data.item.idx }}
      </b-link>
      <b-link
        v-else-if="data.item.type === 'EXPG'"
        :to="{ name: 'campaign-expg-detail', params: { idx: data.item.idx } }"
        class="font-weight-bold d-block text-nowrap"
      >
        {{ data.item.idx }}
      </b-link>
      <div v-else>
        {{ data.value }}
      </div>
    </template>

    <template #cell(brand_name)="data">
      <div class="campaign-brand-name">
        {{ data.item.brand_name }}
      </div>
    </template>

    <template #cell(apply_count)="data">
      {{ data.item.select_count }} / {{ data.item.apply_count }}
    </template>

    <template #cell(sns_channel)="data">
      <div style="width: 80px;">
        <b-avatar-group size="32px">
          <b-avatar
            v-if="parseSns(data.item.sns_channel).includes('INSTAGRAM')"
            rounded
            variant="warning"
            class="pull-up"
          >
            <span>INS</span>
          </b-avatar>
          <b-avatar
            v-if="parseSns(data.item.sns_channel).includes('BLOG')"
            rounded
            variant="success"
            class="pull-up"
          >
            <span>BLG</span>
          </b-avatar>
        </b-avatar-group>
      </div>
    </template>
  </b-table>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BTable,
  BRow,
  BAvatar,
  BAvatarGroup,
  BLink,
} from "bootstrap-vue"

import router from "@/router"

export default defineComponent({
  props: {
    campaigns: Array,
  },

  components: {
    BTable,
    BRow,
    BAvatar,
    BAvatarGroup,
    BLink,
  },

  setup() {
    const tableColumns = [
      { key: "idx", label: "idx" },
      { key: "type", label: "타입" },
      { key: "title", label: "제목" },
      { key: "brand_name", label: "브랜드명" },
      { key: "sns_channel", label: "sns_channel" },
      { key: "step", label: "단계" },
      { key: "is_endless", label: "상시여부", formatter: val => (val ? "상시" : "비상시") },
      { key: "apply_count", label: "선정자/신청자" },
    ]

    const parseSns = snsList => {
      if (!snsList) return []
      return snsList
    }

    const toDetail = item => {
      router.push({ name: "campaign-compt-active-detail", params: { idx: item.idx } })
    }

    return {
      tableColumns,
      parseSns,
      toDetail,
    }
  },
})
</script>

<style scoped>
.campaign-title {
  width: 140px;
  color: #258BFF;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.campaign-brand-name {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

</style>
