import {
  ref,
  onUnmounted,
  computed,
  watch,
} from '@vue/composition-api'
import moment from 'moment'
import uploadFile from '@/hooks/uploadFile'
import store from '@/store'
import router from '@/router'
import storeModule from './storeModule'
import advertiserStoreModule from '@/store/tagby/advertiser'
import useToast from '@/utils/toast'
import useStore from '@/utils/store'

const routerIdx = ref()

const APP_STORE_MODULE_NAME = 'app-advertiser-detail'

export function useInitialize() {
  const initialize = () => {
    routerIdx.value = router.currentRoute.params.idx

    if (!store.hasModule(APP_STORE_MODULE_NAME)) {
      store.registerModule(APP_STORE_MODULE_NAME, storeModule)
    }

    if (!store.hasModule(advertiserStoreModule.STORE_MODULE_NAME)) {
      store.registerModule(advertiserStoreModule.STORE_MODULE_NAME, advertiserStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) {
        store.unregisterModule(APP_STORE_MODULE_NAME)
      }

      if (store.hasModule(advertiserStoreModule.STORE_MODULE_NAME)) {
        store.unregisterModule(advertiserStoreModule.STORE_MODULE_NAME)
      }
    })
  }
  return {
    initialize,
  }
}

const advertiserData = ref({})
const timelineData = ref()
export function useDefault() {
  // 모든 기능에서 공통적으로 사용

  const { makeToast } = useToast()
  const hasLoadError = ref(false)
  const campaigns = ref([])
  const chatCount = ref()
  const claimCount = ref()
  const pendingCautionCount = ref()
  const unselectedCautionCount = ref()
  const etcCautionCount = ref()
  const subscriptions = ref([])
  const businessConfirmState = ref('')

  const fetchDetail = () => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/fetchAdv`,
      { idx: router.currentRoute.params.idx })
      .then(response => {
        advertiserData.value = response.data.data
        campaigns.value = response.data.data.campaigns
        subscriptions.value = response.data.data.subscriptions
        chatCount.value = response.data.data.chat_count
        claimCount.value = response.data.data.claim_count
        pendingCautionCount.value = response.data.data.pending_caution_count
        unselectedCautionCount.value = response.data.data.unselected_caution_count
        etcCautionCount.value = response.data.data.etc_caution_count
        businessConfirmState.value = advertiserData.value.business_confirm_state
      })
      .catch(() => {
        hasLoadError.value = true
      })
  }

  const fetchTimelines = () => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/fetchTimelines`,
      { idx: router.currentRoute.params.idx })
      .then(response => {
        timelineData.value = response.data.data
      })
      .catch(() => {
        makeToast('danger', '타임라인을 가져오는데 실패했습니다')
      })
  }

  const isSaving = ref(false)
  const saveButtonText = computed(() => (isSaving.value ? '저장중' : '저장'))

  const updateAdvertiser = () => {
    isSaving.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/updateAdv`, {
      idx: advertiserData.value.idx,
      name: advertiserData.value.name,
      phone: advertiserData.value.phone,
      email: advertiserData.value.email,
      company_name: advertiserData.value.company_name,
      biz_type: advertiserData.value.biz_type,
      address: advertiserData.value.address,
      address_detail: advertiserData.value.address_detail,
      member_code: advertiserData.value.member_code,
    })
      .then(() => {
        makeToast('primary', '사용자 정보수정에 성공했습니다')
        fetchTimelines()
      })
      .catch(() => {
        makeToast('danger', '사용자 정보수정에 실패했습니다')
      })
      .finally(() => {
        isSaving.value = false
        fetchDetail()
      })
  }

  // Memo
  const visibleMemoSidebar = ref(false)
  const memoContent = ref()
  const isValidMemo = computed(() => Boolean(memoContent.value))
  const isSavingMemo = ref(false)
  const turnOnMemoSidebar = () => { visibleMemoSidebar.value = true }
  const turnOffMemoSidebar = () => {
    memoContent.value = null
    visibleMemoSidebar.value = false
  }
  const saveMemo = () => {
    isSavingMemo.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/saveMemo`,
      {
        idx: advertiserData.value.idx,
        content: memoContent.value,
      }).then(() => {
        makeToast('primary', '메모 생성에 성공했습니다')
        turnOffMemoSidebar()
        fetchTimelines()
      }).catch(() => {
        makeToast('danger', '메모 생성에 실패했습니다')
      }).finally(() => {
        isSavingMemo.value = false
      })
  }

  // AddCautionSidebar
  const reasonTypeSelected = ref()
  const reasonTypeOptions = [
    { label: '캠페인 보류', value: 'PENDING' },
    { label: '캠페인 미선정', value: 'UNSELECTED' },
    { label: '기타', value: 'ETC' },
  ]
  const onInputReasonTypeSelected = value => {
    reasonTypeSelected.value = value
  }
  const infoTable = ref()
  const infoTableOptions = [
    { text: '예', value: 'campaign_info' },
    { text: '아니오', value: 'etc' },
  ]
  const infoIdx = ref()
  const reasonSelected = ref()
  const reasonOptions = [
    '포스팅 원고 검수 지연으로 인플루언서의 캠페인 진행에 차질을 준 경우',
    '캠페인 등록 시 설정하지 않은 매체에서 인플루언서 포스팅을 함부로 활용한 경우',
    '캠페인을 인플루언서 모집이 아닌 광고, 홍보 등 다른 목적으로 사용한 경우',
    '인플루언서의 개인 정보를 캠페인 진행 목적이 아닌 다른 수단으로 사용한 경우',
    { label: '기타 (직접입력)', value: 'etc' },
  ]
  const writtenReason = ref()
  const onInputInfoTable = value => {
    infoTable.value = value
    infoIdx.value = null
    reasonSelected.value = null
  }
  const onInputInfoIdx = value => {
    infoIdx.value = value
  }
  const onInputReasonSelected = value => {
    reasonSelected.value = value
    writtenReason.value = null
  }
  const onInputWrittenReason = value => {
    writtenReason.value = value
  }
  const isSavingAddCaution = ref(false)
  const isEtcReasonStep = computed(() => reasonTypeSelected.value?.value === 'ETC')
  const isCampaignStep = computed(() => infoTable.value === 'campaign_info')
  const isDirectlyWrittenReason = computed(() => (reasonSelected.value?.value === 'etc' || infoTable.value === 'etc'))

  const isValidAddCaution = computed(() => {
    if (reasonTypeSelected.value?.value === 'PENDING') {
      return Boolean(infoIdx.value)
    }
    if (reasonTypeSelected.value?.value === 'UNSELECTED') {
      return Boolean(infoIdx.value)
    }
    if (infoTable.value === 'campaign_info') {
      if (isDirectlyWrittenReason.value) {
        return Boolean(infoIdx.value) && Boolean(writtenReason.value)
      }
      return Boolean(infoIdx.value) && Boolean(reasonSelected.value)
    }
    if (infoTable.value === 'etc') {
      return Boolean(writtenReason.value)
    }
    return false
  })

  watch(reasonTypeSelected, reason => {
    if (reason?.value === 'PENDING') {
      infoTable.value = 'campaign_info'
      infoIdx.value = null
      reasonSelected.value = null
      writtenReason.value = '인원 선정 후 회원의 과실로 캠페인이 보류 상태로 변경된 경우'
    } else if (reason?.value === 'UNSELECTED') {
      infoTable.value = 'campaign_info'
      infoIdx.value = null
      reasonSelected.value = null
      writtenReason.value = '참여 인플루언서를 기간 내에 선정하지 않아 캠페인이 보류 상태로 변경된 경우'
    } else {
      infoTable.value = null
      infoIdx.value = null
      reasonSelected.value = null
      writtenReason.value = null
    }
  })

  const visibleAddCautionSidebar = ref(false)
  const tunrOnAddCautionSidebar = () => { visibleAddCautionSidebar.value = true }
  const turnOffAddNewSnsSidebar = () => {
    reasonTypeSelected.value = null
    visibleAddCautionSidebar.value = false
  }

  const addCaution = () => {
    isSavingAddCaution.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/addCaution`,
      {
        member_idx: advertiserData.value.idx,
        reason_type: reasonTypeSelected.value.value,
        info_table: infoTable.value,
        info_idx: infoIdx.value,
        reason: writtenReason.value ?? reasonSelected.value,
      }).then(() => {
        makeToast('primary', '경고 추가에 성공했습니다')
        turnOffAddNewSnsSidebar()
        fetchDetail()
        fetchTimelines()
      }).catch(() => {
        makeToast('danger', '경고 추가에 실패했습니다')
      }).finally(() => {
        isSavingAddCaution.value = false
      })
  }

  // ManageCautionSidebar
  const itemsOfManageCaution = ref([])
  const resolveReasonType = reasonType => {
    if (reasonType === 'PENDING') {
      return '보류'
    } if (reasonType === 'UNSELECTED') {
      return '미선정'
    } if (reasonType === 'ETC') {
      return '기타'
    }
    return ''
  }
  const fieldsOfManageCaution = [
    'idx',
    'info',
    { key: 'reason_type', label: 'type', formatter: e => resolveReasonType(e) },
    { key: 'details', label: 'reason' },
    'state',
    { key: 'created_at', label: 'created_at', formatter: e => moment(e).format('YYYY-MM-DD HH:mm:ss') },
  ]
  const cautionStateOptions = [
    { text: '추가', value: 'ADDITION' },
    { text: '취소', value: 'CANCELED' },
  ]
  const fetchCautions = () => {
    itemsOfManageCaution.value = []
    store.dispatch(`${APP_STORE_MODULE_NAME}/fetchCautions`,
      {
        adv_idx: advertiserData.value.idx,
      })
      .then(response => {
        itemsOfManageCaution.value = response.data.data
      })
      .catch(() => {
        makeToast('danger', '경고목록을 조회하는데 실패했습니다')
      })
  }
  const onChangeCautionState = params => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/updateCautionState`,
      {
        caution_idx: params.idx,
        state: params.value,
      })
      .then(() => {
        makeToast('primary', '경고목록을 업데이트하는데 성공했습니다')
        fetchDetail()
        fetchTimelines()
      })
      .catch(() => {
        makeToast('danger', '경고목록을 업데이트하는데 실패했습니다')
      })
      .finally(() => {
        fetchCautions()
      })
  }
  const visibleManageCautionSidebar = ref(false)
  const turnOnManageCautionSidebar = () => {
    visibleManageCautionSidebar.value = true
    fetchCautions()
  }
  const turnOffManageCautionSidebar = () => {
    visibleManageCautionSidebar.value = false
  }

  // DeleteAdvertiserSidebar
  const reasonTypeSelectedOfDeleteAdvertiser = ref()
  const reasonTypeOPtionsOfDeleteAdvertiser = [
    '원하는 인플루언서가 없습니다.',
    '이용요금이 비쌉니다.',
    '사용하기 불편합니다/어렵습니다.',
    '다른 서비스를 이용하고 있습니다.',
    '자주 이용하지 않습니다.',
    '기타',
  ]
  const onInputReasonTypeSelectedOfDeleteAdvertiser = value => {
    reasonTypeSelectedOfDeleteAdvertiser.value = value
  }
  const reasonOfDeleteAdvertiser = ref()
  const onInputReasonOfDeleteAdvertiser = value => {
    reasonOfDeleteAdvertiser.value = value
  }
  const visibleDeleteAdvertiserSidebar = ref(false)
  const turnOnDeleteAdvertiserSidebar = () => {
    visibleDeleteAdvertiserSidebar.value = true
  }
  const turnOffDeleteAdvertiserSidebar = () => {
    reasonTypeSelectedOfDeleteAdvertiser.value = null
    reasonOfDeleteAdvertiser.value = null
    visibleDeleteAdvertiserSidebar.value = false
  }
  const isSavingDeleteAdvertiser = ref(false)
  const isValidDeleteAdvertiser = computed(() => Boolean(reasonTypeSelectedOfDeleteAdvertiser.value) && Boolean(reasonOfDeleteAdvertiser.value))
  const deleteAdvertiser = () => {
    isSavingDeleteAdvertiser.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/deleteAdv`,
      {
        idx: advertiserData.value.idx,
        reason_type: reasonTypeSelectedOfDeleteAdvertiser.value,
        reason: reasonOfDeleteAdvertiser.value,
      })
      .then(() => {
        makeToast('primary', '사용자의 탈퇴처리에 성공했습니다')
        fetchDetail()
        fetchTimelines()
      })
      .catch(() => {
        makeToast('danger', '사용자의 탈퇴처리에 실패했습니다')
      })
      .finally(() => {
        isSavingDeleteAdvertiser.value = false
        turnOffDeleteAdvertiserSidebar()
      })
  }

  const visibleBusinessSidebar = ref(false)
  const businessObj = ref({
    representative_name: '',
    registration_number: '',
    company_name: '',
    company_contact: '',
    sub_phone: '',
    sub_email: '',
    bank_name: '',
    holder: '',
    bank_number: '',
    registration_file_url: '',
    bank_file_url: '',
  })
  const turnOnBusinessSidebar = async () => {
    visibleBusinessSidebar.value = true
    try {
      const result = await store.dispatch(
        `${APP_STORE_MODULE_NAME}/fetchBusinessData`,
        {
          idx: router.currentRoute.params.idx,
        },
      )
      businessObj.value = result.data.data
    } catch (e) {
      const responseCode = e.response?.data.code
      if (responseCode === 404) {
        // makeToast('danger', '제출된 사업자 정보가 없습니다.')
      } else {
        makeToast('danger', '알 수 없는 오류')
      }
    }
  }
  const turnOffBusinessSidebar = () => {
    visibleBusinessSidebar.value = false
  }

  const confirmBusiness = async () => {
    try {
      await store.dispatch(
        `${APP_STORE_MODULE_NAME}/confirmBusiness`,
        {
          idx: router.currentRoute.params.idx,
        },
      )
      makeToast('primary', '승인 완료하였습니다.')
    } catch (e) {
      makeToast('danger', '승인에 실패하였습니다.')
    }
    businessObj.value.state = 'ACTIVE'
  }

  const addFile = async (f, key) => {
    let fileObj
    try {
      fileObj = await uploadFile(f, 'business_info', key)
    } catch (e) {
      makeToast('danger', 'API request fail', e.response.data.msg)
    }

    if (key === 'REGISTRATION') {
      businessObj.value.registration_file_url = fileObj.file_url
    } else if (key === 'BANK') {
      businessObj.value.bank_file_url = fileObj.file_url
    }
  }

  const submitBusiness = async () => {
    try {
      const result = await store.dispatch(
        `${APP_STORE_MODULE_NAME}/updateBusiness`,
        {
          advIdx: advertiserData.value.idx,
          ...businessObj.value,
        },
      )
      console.log(result.data)
      makeToast('primary', '사업자정보 제출을 완료하였습니다.')
    } catch (e) {
      console.log(e)
      makeToast('danger', '사업자정보 제출에 실패하였습니다.')
    }
  }

  const visibleBanModal = ref(false)
  const reasonOfBan = ref()
  const inputReasonBan = value => {
    reasonOfBan.value = value
  }
  const turnOnBanModal = () => {
    reasonOfBan.value = null
    visibleBanModal.value = true
  }
  const turnOffBanModal = () => {
    visibleBanModal.value = false
  }
  const ban = () => {
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/ban`, {
        idx: advertiserData.value.idx,
        reason: reasonOfBan.value,
      })
      .then(() => {
        fetchDetail()
        fetchTimelines()
        makeToast('primary', '캠페인 모집중단에 성공하였습니다')
      })
      .catch(() => {
        makeToast('danger', '캠페인 모집중단에 실패했습니다')
      })
      .finally(() => {
      })
  }

  return {
    hasLoadError,
    advertiserData,
    campaigns,
    chatCount,
    claimCount,
    pendingCautionCount,
    unselectedCautionCount,
    etcCautionCount,
    subscriptions,
    timelineData,
    businessConfirmState,
    fetchDetail,
    fetchTimelines,

    isSaving,
    saveButtonText,
    updateAdvertiser,

    // Memo
    visibleMemoSidebar,
    memoContent,
    isValidMemo,
    isSavingMemo,
    turnOnMemoSidebar,
    turnOffMemoSidebar,
    saveMemo,

    // AddCautionSidebar
    visibleAddCautionSidebar,
    tunrOnAddCautionSidebar,
    turnOffAddNewSnsSidebar,
    reasonTypeSelected,
    reasonTypeOptions,
    infoTable,
    infoTableOptions,
    infoIdx,
    reasonSelected,
    reasonOptions,
    writtenReason,
    isSavingAddCaution,
    isValidAddCaution,
    isEtcReasonStep,
    isCampaignStep,
    isDirectlyWrittenReason,
    addCaution,
    onInputReasonTypeSelected,
    onInputInfoTable,
    onInputInfoIdx,
    onInputReasonSelected,
    onInputWrittenReason,

    // ManageCautionSidebar
    visibleManageCautionSidebar,
    turnOnManageCautionSidebar,
    turnOffManageCautionSidebar,
    itemsOfManageCaution,
    fieldsOfManageCaution,
    cautionStateOptions,
    fetchCautions,
    onChangeCautionState,

    // DeleteAdvertiserSidebar
    reasonTypeSelectedOfDeleteAdvertiser,
    reasonTypeOPtionsOfDeleteAdvertiser,
    onInputReasonTypeSelectedOfDeleteAdvertiser,
    reasonOfDeleteAdvertiser,
    onInputReasonOfDeleteAdvertiser,
    visibleDeleteAdvertiserSidebar,
    turnOnDeleteAdvertiserSidebar,
    turnOffDeleteAdvertiserSidebar,
    isSavingDeleteAdvertiser,
    isValidDeleteAdvertiser,
    deleteAdvertiser,

    // business sidebar
    visibleBusinessSidebar,
    turnOnBusinessSidebar,
    turnOffBusinessSidebar,
    businessObj,
    confirmBusiness,
    addFile,
    submitBusiness,

    // ban modal
    visibleBanModal,
    reasonOfBan,
    inputReasonBan,
    turnOnBanModal,
    turnOffBanModal,
    ban,
  }
}

const billingList = ref([])
export function useBillingList() {
  const billingFields = [
    { key: 'idx', label: 'idx' },
    { key: 'card_name', label: 'card_name' },
    { key: 'state', label: 'state' },
    { key: 'represent', label: 'represent' },
    { key: 'created_at', label: 'created_at' },
  ]

  const { makeToast } = useToast()
  const { dispatch } = useStore(advertiserStoreModule.STORE_MODULE_NAME)
  const fetchBillingList = () => {
    dispatch('fetchBillingList', {
      adv_idx: routerIdx.value,
    }).then(response => {
      billingList.value = response.data.data
    }).catch(() => {
      makeToast('danger', '카드 정보를 가져오는데 실패했습니다')
    }).finally(() => {
    })
  }

  return {
    billingList,
    billingFields,
    fetchBillingList,
  }
}

const billingLog = ref([])
export function useBillingLog() {
  const totalRows = ref(0)
  const perPage = ref(5)
  const currentPage = ref(1)
  const isBusy = ref(false)

  const billingLogFields = [
    { key: 'idx', label: 'idx' },
    { key: 'billing_idx', label: 'billing_idx' },
    { key: 'text_log', label: 'text_log' },
    { key: 'pay__type', label: 'pay_type' },
    { key: 'pay__description', label: 'description' },
    { key: 'created_at', label: 'created_at' },
  ]

  const { makeToast } = useToast()
  const { dispatch } = useStore(advertiserStoreModule.STORE_MODULE_NAME)
  const fetchBillingLog = () => {
    dispatch('fetchBillingLog', {
      adv_idx: routerIdx.value,
      page: currentPage.value,
    }).then(response => {
      billingLog.value = response.data.data
      totalRows.value = response.data.page_info.total_count
    }).catch(() => {
      makeToast('danger', '카드 기록을 가져오는데 실패했습니다')
    }).finally(() => {
    })
  }

  const changePage = params => {
    currentPage.value = params.page
    fetchBillingLog()
  }

  return {
    totalRows,
    perPage,
    currentPage,
    isBusy,

    billingLogFields,
    billingLog,
    fetchBillingLog,
    changePage,
  }
}

const payList = ref([])
export function usePayList() {
  const totalRows = ref(0)
  const perPage = ref(5)
  const currentPage = ref(1)
  const isBusy = ref(false)

  const payListFields = [
    { key: 'min_idx', label: 'idx' },
    { key: 'billing_idx', label: 'billing_idx' },
    { key: 'pay_type', label: '결제수단' },
    { key: 'target_type', label: '결제타겟' },
    { key: 'description', label: '내용' },
    { key: 'total_amount', label: '결제총액' },
    { key: 'created_at', label: '결제일시' },
  ]

  const { makeToast } = useToast()
  const { dispatch } = useStore(advertiserStoreModule.STORE_MODULE_NAME)
  const fetchPayList = () => {
    dispatch('fetchPayList', {
      adv_idx: routerIdx.value,
      page: currentPage.value,
    }).then(response => {
      payList.value = response.data.data
      totalRows.value = response.data.page_info.total_count
    }).catch(() => {
      makeToast('danger', '카드 기록을 가져오는데 실패했습니다')
    }).finally(() => {
    })
  }

  const changePage = params => {
    currentPage.value = params.page
    fetchPayList()
  }

  return {
    totalRows,
    perPage,
    currentPage,
    isBusy,

    payListFields,
    payList,
    fetchPayList,
    changePage,
  }
}

const visibleReleaseBanModal = ref(false)
export function useReleaseBan() {
  const isValid = computed(() => {
    const state = advertiserData.value?.state
    return state === 'BAN' || state === 'BAN_WITHDRAW'
  })

  const turnOnModal = () => {
    visibleReleaseBanModal.value = true
  }
  const turnOffModal = () => {
    visibleReleaseBanModal.value = false
  }

  const { makeToast } = useToast()
  const { dispatch } = useStore(advertiserStoreModule.STORE_MODULE_NAME)
  const { fetchDetail, fetchTimelines } = useDefault()
  const releaseBan = () => {
    dispatch('releaseBan', {
      adv_idx: routerIdx.value,
    }).then(() => {
      makeToast('primary', '블랙해제에 성공했습니다')
      fetchDetail()
      fetchTimelines()
    }).catch(() => {
      makeToast('danger', '블랙해제에 실패했습니다')
    }).finally(() => {
      turnOffModal()
    })
  }

  return {
    visibleReleaseBanModal,
    isValid,
    turnOnModal,
    turnOffModal,
    releaseBan,
  }
}

const monthlySubsList = ref([])
export function useMonthlySubscriptionTable() {
  const { dispatch } = useStore(advertiserStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchMonthlySubscription = () => {
    dispatch('fetchMonthlySubscription', {
      idx: router.currentRoute.params.idx,
    }).then(response => {
      monthlySubsList.value = response.data.data
    }).catch(() => {
      makeToast('danger', '구독 정보를 가져오는데 실패했습니다')
    }).finally(() => {
    })
  }
  const fields = [
    { key: 'name', label: '구독상품명' },
    { key: 'continue_count', label: '구독유지기간', formatter: value => `${value}달` },
    { key: 'end_at', label: '구독종료일자' },
    { key: 'state', label: '상태' },
    { key: 'actions', label: 'actions' },
  ]

  return {
    subscriptions: monthlySubsList,
    fetchMonthlySubscription,
    fields,
  }
}

const visibleRefundSubscriptionModal = ref(false)
const refundTargetSubsIdx = ref()
export function useRefundSubscription() {
  const { dispatch } = useStore(advertiserStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchMonthlySubscription } = useMonthlySubscriptionTable()
  const { fetchTimelines } = useDefault()
  const { fetchBillingLog } = useBillingLog()

  const canTurnOnModal = subsState => subsState === 'ACTIVE'

  const turnOnModal = subsIdx => {
    refundTargetSubsIdx.value = subsIdx
    console.log(subsIdx)
    visibleRefundSubscriptionModal.value = true
  }
  const turnOffModal = () => {
    visibleRefundSubscriptionModal.value = false
  }

  const refundSubs = () => {
    dispatch('refundSubs', {
      subs_idx: refundTargetSubsIdx.value,
    }).then(() => {
      makeToast('primary', '구독권 환불에 성공했습니다')
      fetchMonthlySubscription()
      fetchBillingLog()
      fetchTimelines()
    }).catch(() => {
      makeToast('danger', '구독권 환불에 실패했습니다')
    })
  }

  return {
    visibleRefundSubscriptionModal,
    refundTargetSubsIdx,
    canTurnOnModal,
    turnOnModal,
    turnOffModal,
    refundSubs,
  }
}

const paugSubscriptions = ref([])
export function usePaugSubscriptionTable() {
  const { dispatch } = useStore(advertiserStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fetchPaugSubscription = () => {
    dispatch('fetchPaugSubscription', {
      idx: router.currentRoute.params.idx,
    }).then(response => {
      paugSubscriptions.value = response.data.data
    }).catch(() => {
      makeToast('danger', '단건 결제 정보를 가져오는데 실패했습니다')
    }).finally(() => {
    })
  }
  const fields = [
    { key: 'idx', label: 'idx' },
    { key: 'campaign_idx', label: 'campaign_idx' },
    { key: 'apply_count', label: 'apply_count' },
    { key: 'state', label: '상태' },
    { key: 'created_at', label: '지불일시' },
    { key: 'actions', label: 'actions' },
  ]
  const resolveApplyCount = data => `${data.item.meta?.apply_cnt ?? 0}명`
  const resolveCampaignIdx = data => data.item.meta?.campaign_idx

  return {
    subscriptions: paugSubscriptions,
    fetchPaugSubscription,
    fields,
    resolveApplyCount,
    resolveCampaignIdx,
  }
}

const visibleRefundPaugModal = ref(false)
const refundTargetPaugIdx = ref()
export function useRefundPaug() {
  const { dispatch } = useStore(advertiserStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchPaugSubscription } = usePaugSubscriptionTable()
  const { fetchTimelines } = useDefault()
  const { fetchBillingLog } = useBillingLog()

  const turnOnModal = paugIdx => {
    refundTargetPaugIdx.value = paugIdx
    visibleRefundPaugModal.value = true
  }
  const turnOffModal = () => {
    visibleRefundPaugModal.value = false
  }

  const refundPaug = () => {
    dispatch('refundPaug', {
      paug_idx: refundTargetPaugIdx.value,
    }).then(() => {
      makeToast('primary', '건별결제 환불에 성공했습니다')
      fetchPaugSubscription()
      fetchBillingLog()
      fetchTimelines()
    }).catch(() => {
      makeToast('danger', '건별결제 환불에 실패했습니다')
    })
  }

  return {
    visibleRefundPaugModal,
    refundTargetPaugIdx,
    turnOnModal,
    turnOffModal,
    refundPaug,
  }
}

const visibleUpdateSuggestionCountSidebar = ref(false)
const suggestionCount = ref()
export function useUpdateSuggestionCount() {
  const { dispatch } = useStore(advertiserStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchTimelines, fetchDetail } = useDefault()

  const turnOnSidebar = () => {
    visibleUpdateSuggestionCountSidebar.value = true
    suggestionCount.value = advertiserData.value?.suggestion_count
  }

  const turnOffSidebar = () => {
    visibleUpdateSuggestionCountSidebar.value = false
  }

  const isSaving = ref(false)

  const updateSuggestionCount = () => {
    isSaving.value = true
    dispatch('updateSuggestionCount', {
      adv_idx: routerIdx.value,
      suggestion_count: suggestionCount.value,
    }).then(() => {
      makeToast('primary', '제안하기 횟수 수정에 성공했습니다')
      fetchDetail()
      fetchTimelines()
      turnOffSidebar()
    }).catch(error => {
      const { code: tagbyCode } = error.response.data
      if (tagbyCode === 404) {
        makeToast('danger', '제안하기 횟수 수정에 실패했습니다', '구독권이 존재하지 않습니다')
      } else if (tagbyCode === 407) {
        makeToast('danger', '제안하기 횟수 수정에 실패했습니다', '구독권이 PREMIUM이 아닙니다')
      } else {
        makeToast('danger', '제안하기 횟수 수정에 실패했습니다')
      }
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    visibleUpdateSuggestionCountSidebar,
    suggestionCount,
    isSaving,
    turnOnSidebar,
    turnOffSidebar,
    updateSuggestionCount,
  }
}

const visibleRepaySidebar = ref(false)
const repayPid = ref()
export function useRepay() {
  const { dispatch } = useStore(advertiserStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchTimelines } = useDefault()
  const { fetchPayList } = usePayList()
  const { fetchBillingList } = useBillingList()

  const activeBillingList = computed(() => billingList.value?.filter(e => e.state === 'REGIST'))

  const selectedCard = ref()

  const turnOnSidebar = pid => {
    repayPid.value = pid
    visibleRepaySidebar.value = true
  }

  const turnOffSidebar = () => {
    visibleRepaySidebar.value = false
  }

  const isSaving = ref(false)
  const isValid = computed(() => selectedCard.value != null)

  const selectCard = billingIdx => {
    selectedCard.value = billingIdx
  }

  const repay = () => {
    isSaving.value = true
    dispatch('repay', {
      adv_idx: routerIdx.value,
      pid: repayPid.value,
      billing_idx: selectedCard.value,
    }).then(() => {
      makeToast('primary', '다른 카드로 재결제에 성공했습니다')
      fetchBillingList()
      fetchPayList()
      fetchTimelines()
      turnOffSidebar()
    }).catch(() => {
      makeToast('danger', '다른 카드로 재결제에 실패했습니다', '프로세스도중 환불만 성공했을수도 있으니 확인이 필요합니다.')
      fetchBillingList()
      fetchPayList()
      fetchTimelines()
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    visibleRepaySidebar,
    turnOnSidebar,
    turnOffSidebar,
    isSaving,
    isValid,
    selectCard,
    repay,
    activeBillingList,
    selectedCard,
  }
}

const visibleCancelPayModal = ref(false)
const cancelPid = ref()
export function useCancelPay() {
  const { dispatch } = useStore(advertiserStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchTimelines } = useDefault()
  const { fetchPayList } = usePayList()
  const { fetchBillingList } = useBillingList()

  const turnOnModal = pid => {
    cancelPid.value = pid
    visibleCancelPayModal.value = true
  }

  const turnOffModal = () => {
    visibleCancelPayModal.value = false
  }

  const cancelPay = () => {
    dispatch('cancelPay', {
      adv_idx: routerIdx.value,
      pid: cancelPid.value,
    }).then(() => {
      makeToast('primary', '결제 취소에 성공했습니다')
      fetchBillingList()
      fetchPayList()
      fetchTimelines()
      turnOffModal()
    }).catch(() => {
      makeToast('danger', '결제 취소에 실패했습니다', '프로세스도중 환불만 성공했을수도 있으니 확인이 필요합니다.')
      fetchBillingList()
      fetchPayList()
      fetchTimelines()
    })
  }

  return {
    visibleCancelPayModal,
    turnOnModal,
    turnOffModal,
    cancelPay,
  }
}
