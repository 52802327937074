<template>
  <BCard title="카드 기록">
    <TagbyList
      v-model="currentPage"
      :items="billingLog"
      :fields="billingLogFields"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(pay__type)="data">
        <div v-if="data.item.pay != null">
          {{ data.item.pay.target_type }}
        </div>
      </template>

      <template #cell(pay__description)="data">
        <div v-if="data.item.pay != null">
          {{ data.item.pay.description }}
        </div>
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
} from 'bootstrap-vue'
import { useBillingLog } from '../viewModel'
import resolveValue from '@/utils/resolveValue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    BCard,
    TagbyList,
    TagbyDatetimeColumn,
  },
  setup() {
    const {
      totalRows,
      perPage,
      currentPage,
      isBusy,

      billingLogFields,
      billingLog,
      fetchBillingLog,
      changePage,
    } = useBillingLog()
    fetchBillingLog()

    const { resolveDateTime } = resolveValue()

    return {
      totalRows,
      perPage,
      currentPage,
      isBusy,

      billingLogFields,
      billingLog,
      fetchBillingLog,
      changePage,
      resolveDateTime,
    }
  },
})
</script>
