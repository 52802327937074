<template>
  <TagbyActionButton
    :isValid="isValid"
    variant="outline-secondary"
    text="블랙해제"
    @click="turnOnModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import { useReleaseBan } from '../viewModel'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      isValid,
      turnOnModal,
    } = useReleaseBan()
    return {
      isValid,
      turnOnModal,
    }
  },
})
</script>
