<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-primary"
          :disabled="isSaving"
          block
          @click="$emit('save-advertiser')"
        >
          <b-spinner
            v-if="isSaving"
            small
          />
          {{ saveButtonText }}
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <ReleaseBanButton v-if="isValidReleaseBan" />
        <b-button
          v-else
          variant="outline-secondary"
          block
          @click="$emit('turn-on-ban-modal')"
        >
          블랙
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-secondary"
          block
          @click="$emit('turn-on-add-caution-sidebar')"
        >
          경고추가
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-secondary"
          block
          @click="$emit('turn-on-memo-sidebar')"
        >
          메모
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          :variant="businessConfirmState == 'ACTIVE' ? 'outline-success' : 'outline-secondary' "
          :class="businessConfirmState"
          block
          @click="$emit('turn-on-business-sidebar')"
        >
          {{ translateConfirmState(businessConfirmState) }}
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-danger"
          block
          @click="$emit('turn-on-delete-advertiser')"
        >
          회원탈퇴
        </b-button>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-secondary"
          block
          @click="turnOnUpdateSuggestionCountSidebar"
        >
          제안 횟수 수정
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'
import ReleaseBanButton from './ReleaseBanButton.vue'
import {
  useReleaseBan,
  useUpdateSuggestionCount,
} from '../viewModel'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BSpinner,
    ReleaseBanButton,
  },
  props: {
    businessConfirmState: {
      type: String,
      required: true,

    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    saveButtonText: {
      type: String,
      required: true,
    },
  },

  emits: [
    'save-advertiser',
    'turn-on-add-caution-sidebar',
    'turn-on-memo-sidebar',
    'turn-on-delete-advertiser',
    'turn-on-business-sidebar',
  ],

  methods: {
    translateConfirmState(state) {
      let translatedState
      switch (state) {
        case 'WAIT':
          translatedState = '사업자 승인 필요'
          break
        case 'ACTIVE':
          translatedState = '사업자 정보'
          break
        case 'INACTIVE':
          translatedState = '제출 안 됨'
          break
        default:
          translatedState = '사업자 정보 없음'
          break
      }
      return translatedState
    },
  },
  setup() {
    const { isValid: isValidReleaseBan } = useReleaseBan()
    const {
      turnOnSidebar: turnOnUpdateSuggestionCountSidebar,
    } = useUpdateSuggestionCount()
    return {
      isValidReleaseBan,
      turnOnUpdateSuggestionCountSidebar,
    }
  },
}
</script>

<style lang="scss">
.WAIT {
  color: yellow;
  border-color: yellow;
}

</style>
