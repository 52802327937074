<template>
  <b-card title="건별결제">
    <b-table
      :items="subscriptions"
      :fields="fields"
      show-empty
      responsive
      small
    >
      <template #cell(campaign_idx)="data">
        <BLink
          v-if="resolveCampaignIdx(data)"
          :to="{ name: 'campaign-compt-active-detail', params: {idx: resolveCampaignIdx(data)} }"
        >
          {{ resolveCampaignIdx(data) }}
        </BLink>
      </template>

      <template #cell(apply_count)="data">
        {{ resolveApplyCount(data) }}
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            icon="XSquareIcon"
            text="건별결제 환불"
            @click="turnOnModal(data.item.idx)"
          />
        </TagbyTableAction>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BTable,
  BCard,
  BLink,
} from 'bootstrap-vue'
import {
  usePaugSubscriptionTable,
  useRefundPaug,
} from '../viewModel'
import resolveValue from '@/utils/resolveValue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
    TagbyTableAction,
    TagbyTableActionItem,
    TagbyDatetimeColumn,
  },
  setup() {
    const {
      subscriptions,
      fetchPaugSubscription,
      fields,
      resolveApplyCount,
      resolveCampaignIdx,
    } = usePaugSubscriptionTable()
    fetchPaugSubscription()

    const {
      turnOnModal,
    } = useRefundPaug()

    const { resolveDateTime } = resolveValue()

    return {
      subscriptions,
      fetchPaugSubscription,
      fields,
      resolveDateTime,
      resolveApplyCount,
      resolveCampaignIdx,
      turnOnModal,
    }
  },
})
</script>
