<template>
  <tagby-sidebar
    :visible="visible"
    width="30%"
    @hidden="$emit('hidden')"
  >
    <template #title>
      경고 추가
    </template>

    <template #content>
      <div
        class="mb-1"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="문제타입"
            >
              <v-select
                :value="reasonTypeSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="reasonTypeOptions"
                class="invoice-filter-select"
                placeholder="검색 항목 선택"
                @input="$emit('input-reason-type-selected', $event)"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isEtcReasonStep">
          <b-col>
            <b-form-group
              label="캠페인에 관련된 경고 입니까?"
            >
              <b-form-radio-group
                :value="infoTable"
                :options="infoTableOptions"
                @input="$emit('input-info-table', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isCampaignStep">
          <b-col>
            <b-form-group
              label="캠페인 인덱스"
            >
              <b-form-input
                :value="infoIdx"
                trim
                placeholder="ex) 4260"
                @input="$emit('input-info-idx', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isCampaignStep && isEtcReasonStep">
          <b-col>
            <b-form-group
              label="제한사유"
            >
              <v-select
                :value="reasonSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="reasonOptions"
                class="invoice-filter-select"
                placeholder="검색 항목 선택"
                @input="$emit('input-reason-selected', $event)"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isDirectlyWrittenReason">
          <b-col>
            <b-form-textarea
              :value="writtenReason"
              rows="8"
              placeholder="사유를 입력해주세요"
              @input="$emit('input-written-reason', $event)"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col>
            <b-button
              variant="outline-primary"
              class="mr-1"
              :disabled="!isValid"
              @click="$emit('ok')"
            >
              <b-spinner
                v-if="isSaving"
                small
              />
              확인
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="$emit('hidden')"
            >
              취소
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
  BFormTextarea,
  BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    BFormTextarea,
    BFormRadioGroup,
    vSelect,
    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
    reasonTypeSelected: {
      type: Object,
      default: null,
    },
    reasonTypeOptions: {
      type: Array,
      required: true,
    },
    isEtcReasonStep: {
      type: Boolean,
      required: true,
    },
    infoTable: {
      type: String,
      default: null,
    },
    infoTableOptions: {
      type: Array,
      required: true,
    },
    isCampaignStep: {
      type: Boolean,
      required: true,
    },
    infoIdx: {
      type: String,
      default: null,
    },
    reasonSelected: {
      type: [Object, String],
      default: null,
    },
    reasonOptions: {
      type: Array,
      required: true,
    },
    isDirectlyWrittenReason: {
      type: Boolean,
      required: true,
    },
    writtenReason: {
      type: String,
      default: null,
    },
  },
  emits: [
    'hidden',
    'ok',
    'input-reason-type-selected',
    'input-info-table',
    'input-info-idx',
    'input-reason-selected',
    'input-written-reason',
  ],
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
