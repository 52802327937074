<template>
  <tagby-sidebar
    :visible="visible"
    width="40%"
    @hidden="$emit('hidden')"
  >
    <template #title>
      경고 추가
    </template>

    <template #content>
      <div
        class="mb-1"
      >
        <b-row>
          <b-col>
            <b-table
              ref="cautionTable"
              responsive="sm"
              small
              :fields="fields"
              :items="items"
            >

              <template #cell(state)="data">
                <b-form-select
                  :value="data.value"
                  :options="stateOptions"
                  @change="$emit('change-state', {idx: data.item.idx, value: $event})"
                />
              </template>

              <template #cell(info)="data">
                <div
                  v-if="data.item.info_table=='campaign_info'"
                >
                  <span>
                    캠페인(
                  </span>
                  <b-link :to="{name: 'campaign-compt-active-detail', params: {idx: data.item.info_idx}}">
                    {{ data.item.info_idx }}
                  </b-link>
                  <span>
                    )
                  </span>
                </div>

                <div v-else>
                  기타
                </div>
              </template>

              <template #cell(created_at)="data">
                <div style="max-width: 90px;">
                  {{ data.value }}
                </div>
              </template>

              <template #cell(details)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  button
                  size="sm"
                  button-variant="outline-primary"
                  @change="row.toggleDetails"
                >
                  <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
                </b-form-checkbox>
              </template>

              <template #row-details="row">
                <b-card
                  no-body
                >
                  <span>
                    {{ row.item.reason }}
                  </span>
                </b-card>
              </template>
            </b-table>

          </b-col>
        </b-row>
      </div>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BFormSelect,
  BTable,
  BLink,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BTable,
    BLink,
    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    stateOptions: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'hidden',
    'change-state',
  ],
}
</script>
