<template>
  <TagbyPrimaryModal
    :visible="visibleReleaseBanModal"
    title="블랙해제"
    content="블랙해제를 하시겠습니까? 블랙으로 인해 보류된 캠페인이나 판매 중단된 상품등은 자동으로 복구되지 않습니다."
    @ok="releaseBan"
    @hidden="turnOffModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import { useReleaseBan } from '../viewModel'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      visibleReleaseBanModal,
      turnOffModal,
      releaseBan,
    } = useReleaseBan()
    return {
      visibleReleaseBanModal,
      turnOffModal,
      releaseBan,
    }
  },
})
</script>
