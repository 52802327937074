<template>
  <b-modal
    :visible="visible"
    cancel-variant="outline-secondary"
    ok-variant="danger"
    ok-title="확인"
    cancel-title="취소"
    modal-class="modal-danger"
    centered
    title="블랙"
    @ok="$emit('ok')"
    @hidden="$emit('hidden')"
  >
    <b-form-group label="메모">
      <b-form-textarea
        :value="reason"
        @input="$emit('input-reason', $event)"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    reason: {
      type: String,
      default: '',
    },
  },
}
</script>
