<template>
  <tagby-sidebar
    :visible="visible"
    width="40%"
    @hidden="$emit('hidden')"
  >
    <template #title>
      사업자 정보
    </template>

    <template #content v-if="businessObj">
      <div
        class="mb-1"
      >
        <b-row>
          <b-col cols="6">
            <b-form-group label="대표자명" label-for="representativeName">
              <b-form-input
                id="representativeName"
                v-model="businessObj.representative_name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="사업자번호" label-for="registrationNumber">
              <b-form-input
                id="registrationNumber"
                v-model="businessObj.registration_number"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <b-form-group label="회사명" label-for="companyName">
              <b-form-input
                id="companyName"
                v-model="businessObj.company_name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="연락처" label-for="companyContact">
              <b-form-input
                id="companyContact"
                v-model="businessObj.company_contact"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col cols="6">
            <b-form-group label="보조 연락처" label-for="subPhone">
              <b-form-input
                id="subPhone"
                v-model="businessObj.sub_phone"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="보조 이메일" label-for="subEmail">
              <b-form-input
                id="subEmail"
                v-model="businessObj.sub_email"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <b-form-group
              label="은행명"
              label-for="bankName"
            >
              <v-select
                v-model="businessObj.bank_name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="bankNameOptions"
                class="invoice-filter-select"
                placeholder="검색 항목 선택"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="예금주명" label-for="holder">
              <b-form-input
                id="holder"
                v-model="businessObj.holder"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col>
            <b-form-group label="계좌번호" label-for="bankNumber">
              <b-form-input
                id="bankNumber"
                v-model="businessObj.bank_number"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="사업자등록증" label-for="registrationFileUrl">
              <b-input-group>
                <b-form-input
                  id="registrationFileUrl"
                  :value="getFileNameFromFullPath(businessObj.registration_file_url)"
                  style="color: #B4B7BC; opacity: 1;"
                  readonly
                />
                <b-input-group-append
                  class="cursor-pointer"
                  @click="download(businessObj.registration_file_url)"
                >
                  <label for="registration-img-file" class="download-file-button">다운로드</label>
                  </input>
                </b-input-group-append>
                <b-input-group-append
                  class="cursor-pointer"
                >
                  <input
                    id="registration-img-file"
                    type="file"
                    style="display:none;"
                    @change="$emit('addFile', $event.target.files[0], 'REGISTRATION')"
                  >
                  <label for="registration-img-file" class="input-file-button">업로드</label>
                  </input>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="통장사본" label-for="bankFileUrl">
              <b-input-group>
                <b-form-input
                  id="bankFileUrl"
                  :value="getFileNameFromFullPath(businessObj.bank_file_url)"
                  style="color: #B4B7BC; opacity: 1;"
                  readonly
                />
                <b-input-group-append
                  class="cursor-pointer"
                  @click="download(businessObj.bank_file_url)"
                >
                  <label for="download-file" class="download-file-button">다운로드</label>
                </input>
                </b-input-group-append>
                <b-input-group-append
                  class="cursor-pointer"
                >
                  <input
                    id="bank-img-file"
                    type="file"
                    style="display:none;"
                    @change="$emit('addFile', $event.target.files[0], 'BANK')"
                  >
                  <label for="bank-img-file" class="input-file-button">업로드</label>
                  </input>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button
              v-if="!businessObj.state"
              variant="outline-primary"
              @click="$emit('submit')"
              v-text="'제출'"
            />
            <b-button
              v-else-if="businessObj.state === 'WAIT'"
              variant="outline-primary"
              @click="$emit('confirm')"
              v-text="'승인'"
            />
            <b-button
              v-else
              variant="outline-secondary"
              @click="$emit('submit')"
              v-text="'수정'"
            />
          </b-col>
        </b-row>
      </div>
    </template>

    <template v-else>
      없음
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

import axios from '@axios'
import vSelect from 'vue-select'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,

    vSelect,
    TagbySidebar,
  },
  data() {
    return {
      bankNameOptions: [ "카카오뱅크", "케이뱅크" ]
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    businessObj: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'hidden',
    'change-state',
    'confirm',
  ],

  methods: {
    download(fileUrl) {
      window.open(fileUrl, '_blank')
    },
    getFileNameFromFullPath(fileUrl) {
      return fileUrl.split('\\').pop().split('/').pop()
    },

    async getBankNames() {
      try {
        const result = await axios.get(`${process.env.VUE_APP_BACKEND_SERVER}/info/banks/`)
        this.bankNameOptions = [ ...new Set(Object.values(result.data.data))]
      } catch (e) {
        console.log(e)
      }
    },
  },
  created() {
    this.getBankNames()
  },
}
</script>

<style lang="scss">
.input-file-button{
  padding: 6px 25px;
  border: 1px solid #0078FE;
  border-radius: 4px;
  color: #0078FE;;
  cursor: pointer;
}

.download-file-button {
  padding: 6px 25px;
  border: 1px solid #0078FE;
  border-radius: 4px;
  color: #0078FE;
  cursor: pointer;
}
</style>
