<template>
  <b-card title="Subscription List">
    <b-table
      :items="subscriptions"
      :fields="tableColumns"
      primary-key="idx"
      show-empty
      responsive
      small
    />
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BTable, BCard } from 'bootstrap-vue'

export default defineComponent({
  props: {
    subscriptions: Array,
  },

  components: {
    BCard,
    BTable,
  },

  setup() {
    const tableColumns = [
      { key: 'name', label: '구독상품명' },
      { key: 'continue_count', label: '구독유지기간', formatter: value => `${value}달` },
      { key: 'end_at', label: '구독종료일자' },
      { key: 'state', label: '상태' },
    ]

    return {
      tableColumns,
    }
  },
})
</script>
