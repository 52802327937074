<template>
  <b-card>
    <div class="demo-inline-spacing mb-2">
      <h4
        class="card-title"
        style="margin:0"
      >
        사용자 정보
      </h4>
      <b-badge
        class="ml-1"
        :variant="advertiserData.state === 'ACTIVE' ? 'success':'secondary'"
        style="margin:0"
      >
        {{ advertiserData.state }}
      </b-badge>
      <b-badge
        v-if="advertiserData.channel === 'CAFE24'"
        class="ml-1"
        variant="primary"
        style="margin:0"
      >
        {{ advertiserData.channel }}
      </b-badge>
      <b-badge
        v-if="advertiserData.is_agree"
        class="ml-1"
        variant="info"
        style="margin:0"
      >
        광고성메일수신동의
      </b-badge>
      <b-badge
        v-else
        class="ml-1"
        variant="danger"
        style="margin:0"
      >
        광고성메일수신비동의
      </b-badge>
    </div>
    <b-row class="mb-1">
      <b-col
        cols="6"
        xl="2"
        lg="2"
        md="4"
      >
        <b-avatar
          :text="avatarText(advertiserData.name)"
          variant="light-primary"
          size="120px"
          rounded
        />
      </b-col>
      <b-col
        cols="4"
        xl="2"
        lg="2"
        md="3"
      >
        <b-row class="mb-1">
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <h5 class="mr-1">
              중재
            </h5>
            <div>
              <b-avatar
                variant="light-primary"
                rounded
                :text="`${advertiserData.claim_count}`"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <h5 class="mr-1">
              채팅
            </h5>
            <div>
              <b-avatar
                variant="light-primary"
                rounded
                :text="`${advertiserData.chat_count}`"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <h5 class="mr-1">
              남은 제안
            </h5>
            <div
              style="cursor:pointer"
              @click="moveToSuggestionList"
            >
              <b-avatar
                variant="light-primary"
                rounded
                :text="`${advertiserData.suggestion_count}`"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="5"
        xl="3"
        lg="4"
        md="5"
        class="d-flex flex-column justify-content-between"
      >
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <h5 class="mr-1">
              캠페인 보류
            </h5>
            <div
              style="cursor:pointer"
              @click="$emit('turn-on-caution-manage-sidebar')"
            >
              <b-avatar
                variant="light-primary"
                rounded
                :text="`${pendingCautionCount}`"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <h5 class="mr-1">
              캠페인 미선정
            </h5>
            <div
              style="cursor:pointer"
              @click="$emit('turn-on-caution-manage-sidebar')"
            >
              <b-avatar
                variant="light-primary"
                rounded
                :text="`${unselectedCautionCount}`"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <h5 class="mr-1">
              기타 경고
            </h5>
            <div
              style="cursor:pointer"
              @click="$emit('turn-on-caution-manage-sidebar')"
            >
              <b-avatar
                variant="light-primary"
                rounded
                :text="`${etcCautionCount}`"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="3"
        xl="3"
        lg="3"
        md="3"
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center"
          >
            <span class="mr-1">
              포인트
            </span>
            <span>
              {{ advertiserData.point }}
            </span>
          </b-col>
        </b-row>
        <b-row
          v-if="advertiserData.join_code != null"
          class="mt-2"
        >
          <b-col
            cols="12"
            class="d-flex align-items-center"
          >
            <span class="mr-1">
              가입코드
            </span>
            <span>
              {{ advertiserData.join_code }}
            </span>
          </b-col>
        </b-row>
        <b-row
          v-if="advertiserData.invite_code != null"
          class="mt-2"
        >
          <b-col
            cols="12"
            class="d-flex align-items-center"
          >
            <span class="mr-1">
              추천코드
            </span>
            <span>
              {{ advertiserData.invite_code }}
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        xl="6"
        lg="6"
      >

        <b-form-group>
          <label>Name</label>
          <tagby-copyable-input v-model="advertiserData.name" />
        </b-form-group>

        <b-form-group>
          <label>Phone</label>
          <tagby-copyable-input v-model="advertiserData.phone" />
        </b-form-group>

        <b-form-group>
          <label>Email</label>
          <tagby-copyable-input v-model="advertiserData.email" />
        </b-form-group>

        <b-form-group>
          <label>Type</label>
          <TagbySelect
            v-model="advertiserData.member_code"
            :options="memberCodeOptions"
          />
        </b-form-group>

      </b-col>

      <b-col
        cols="12"
        xl="6"
        lg="6"
      >

        <b-form-group>
          <label>Company</label>
          <tagby-copyable-input v-model="advertiserData.company_name" />
        </b-form-group>

        <b-form-group>
          <label>Biz type</label>
          <tagby-copyable-input v-model="advertiserData.biz_type" />
        </b-form-group>

        <b-form-group>
          <tagby-address-input
            :address.sync="advertiserData.address"
            :address-detail.sync="advertiserData.address_detail"
          />
        </b-form-group>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'
import TagbyAddressInput from '@/components/TagbyAddressInput.vue'
import router from '@/router'
import TagbySelect from '@/components/TagbySelect.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormGroup,
    BBadge,
    TagbyCopyableInput,
    TagbyAddressInput,
    TagbySelect,
  },
  props: {
    advertiserData: {
      type: Object,
      required: true,
    },
    chatCount: {
      type: Number,
      default: 0,
    },
    claimCount: {
      type: Number,
      default: 0,
    },
    pendingCautionCount: {
      type: Number,
      default: 0,
    },
    unselectedCautionCount: {
      type: Number,
      default: 0,
    },
    etcCautionCount: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    'turn-on-caution-manage-sidebar',
  ],
  setup(props) {
    const moveToSuggestionList = () => {
      router.push({ name: 'campaign-suggestion-list', query: { adv_idx_list: [props.advertiserData.idx] } })
    }
    const memberCodeOptions = [
      'USER',
      'TAGGER',
    ]
    return {
      avatarText,
      moveToSuggestionList,
      memberCodeOptions,
    }
  },
}
</script>
