import axios from '@axios'

const STORE_MODULE_NAME = 'advertiser'

const actions = {
  fetchMonthlySubscription(ctx, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/${idx}/monthly-subscription/`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchAdv(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/detail/`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchPaugSubscription(ctx, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/${idx}/paug-subscription/`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchTimelines(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/timelines/`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateAdv(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/update/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  saveMemo(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/memo/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  addCaution(ctx, params) {
    console.log('test')
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/caution/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchCautions(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/cautions/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateCautionState(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/update-caution-state/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  deleteAdv(ctx, userData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/delete/`, userData)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchBusinessData(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/${params.idx}/business/`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  confirmBusiness(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/${params.idx}/confirm-business/`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateBusiness(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/${params.advIdx}/update-business/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  ban(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/ban/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchBillingList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/get-billing-list/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchBillingLog(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/get-billing-log/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  releaseBan(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/release-ban/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  refundSubs(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/refund-subs/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  refundPaug(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/refund-paug/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateSuggestionCount(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/update-suggestion-count/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchPayList(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/pay-list/`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  repay(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/repay/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  cancelPay(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/cancel-pay/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  STORE_MODULE_NAME,
  namespaced: true,
  actions,
}
