<template>
  <BCard title="카드">
    <BTable
      :items="billingList"
      :fields="billingFields"
      small
      show-empty
      responsive
    >
      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(represent)="data">
        <BBadge
          v-if="data.value && data.item.state === 'REGIST'"
          variant="primary"
        >
          대표
        </BBadge>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BBadge,
} from 'bootstrap-vue'
import { useBillingList } from '../viewModel'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BBadge,
    TagbyDatetimeColumn,
  },
  setup() {
    const {
      billingFields,
      billingList,
      fetchBillingList,
    } = useBillingList()
    fetchBillingList()

    return {
      billingFields,
      billingList,
    }
  },
})
</script>
