<template>
  <b-card title="구독권">
    <b-table
      :items="subscriptions"
      :fields="fields"
      primary-key="idx"
      show-empty
      responsive
      small
    >
      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            icon="XSquareIcon"
            text="구독권 환불"
            :disabled="!canTurnOnModal(data.item.state)"
            @click="turnOnModal(data.item.idx)"
          />
        </TagbyTableAction>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BTable, BCard } from 'bootstrap-vue'
import {
  useMonthlySubscriptionTable,
  useRefundSubscription,
} from '../viewModel'
import resolveValue from '@/utils/resolveValue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const {
      subscriptions,
      fetchMonthlySubscription,
      fields,
    } = useMonthlySubscriptionTable()
    fetchMonthlySubscription()

    const {
      canTurnOnModal,
      turnOnModal,
    } = useRefundSubscription()

    const { resolveDateTime } = resolveValue()

    return {
      subscriptions,
      fetchMonthlySubscription,
      fields,
      resolveDateTime,
      canTurnOnModal,
      turnOnModal,
    }
  },
})
</script>
