import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAdv(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/detail/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 타임라인 받기
    fetchTimelines(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/timelines/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 업데이트
    updateAdv(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/update/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 메모 작성
    saveMemo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/memo/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 메모 작성
    addCaution(ctx, params) {
      console.log('test')
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/caution/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // 광고주 경고 목록 받기
    fetchCautions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/cautions/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 경고 상태 변경
    updateCautionState(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/update-caution-state/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 회원탈퇴
    deleteAdv(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/delete/`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchBusinessData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/${params.idx}/business/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    confirmBusiness(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/${params.idx}/confirm-business/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateBusiness(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/${params.advIdx}/update-business/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    ban(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/ban/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
