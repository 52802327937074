<template>
  <b-form-group
    v-if="!isModifying"
  >
    <label>Address</label>
    <tagby-copyable-input
      :value="resolvedAddress"
      @focus="focusAddress"
    />
  </b-form-group>

  <b-row
    v-else
    @focusout="blurAddress"
  >
    <b-col
      cols="12"
      xl="6"
    >
      <b-form-group>
        <label>Address</label>
        <b-form-input
          v-focus
          :value="address"
          @input="$emit('update:address', $event)"
          @mousedown="lockOn"
          @mouseup="lockOff"
        />
      </b-form-group>
    </b-col>

    <b-col
      cols="12"
      xl="6"
    >
      <b-form-group>
        <label>Address Detail</label>
        <b-form-input
          :value="addressDetail"
          @input="$emit('update:address-detail', $event)"
          @mousedown="lockOn"
          @mouseup="lockOff"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  ref,
  computed,
} from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbyCopyableInput from './TagbyCopyableInput.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbyCopyableInput,
  },
  props: {
    address: {
      type: String,
      default: '',
    },
    addressDetail: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const lock = ref(false)
    const isModifying = ref(false)
    const resolvedAddress = computed(() => `${props.address ?? ''} ${props.addressDetail ?? ''}`)

    const focusAddress = () => { isModifying.value = true }
    const blurAddress = () => {
      if (!lock.value) {
        isModifying.value = false
      }
    }
    const lockOn = () => { lock.value = true }
    const lockOff = () => { lock.value = false }

    return {
      lock,
      isModifying,
      resolvedAddress,
      focusAddress,
      blurAddress,
      lockOn,
      lockOff,
    }
  },
}
</script>
