<template>
  <div>
    <TagbyDetailLayout>
      <template #error>
        <TagbyLoadError
          :hasLoadError="hasLoadError"
          subject="User"
          :to="{ name: 'member-adv-list'}"
        />
      </template>

      <template #content>
        <advertiser-info-card
          v-if="advertiserData"
          :advertiser-data="advertiserData"
          :chat-count="chatCount"
          :claim-count="claimCount"
          :pending-caution-count="pendingCautionCount"
          :unselected-caution-count="unselectedCautionCount"
          :etc-caution-count="etcCautionCount"
          @turn-on-caution-manage-sidebar="turnOnManageCautionSidebar"
        />
        <advertiser-campaign-card
          v-if="campaigns"
          :campaigns="campaigns"
        />
        <SubscriptionTableCard />
        <PaugTableCard />
        <BillingList />
        <BillingLog />
        <PayList />
      </template>

      <template #side>
        <action-card
          :is-saving="isSaving"
          :save-button-text="saveButtonText"
          :businessConfirmState="businessConfirmState"
          @save-advertiser="updateAdvertiser"
          @turn-on-memo-sidebar="turnOnMemoSidebar"
          @turn-on-add-caution-sidebar="tunrOnAddCautionSidebar"
          @turn-on-delete-advertiser="turnOnDeleteAdvertiserSidebar"
          @turn-on-business-sidebar="turnOnBusinessSidebar"
          @turn-on-ban-modal="turnOnBanModal"
        />
        <tagby-timeline
          v-if="timelineData"
          title="타임라인"
          :timeline-data="timelineData"
        />
      </template>

      <template #hidden>
        <tagby-memo
          v-model="memoContent"
          :visible="visibleMemoSidebar"
          :is-saving="isSavingMemo"
          @hidden="turnOffMemoSidebar"
          @save="saveMemo"
        />

        <add-caution-sidebar
          :visible="visibleAddCautionSidebar"
          :is-saving="isSavingAddCaution"
          :is-valid="isValidAddCaution"
          :reason-type-selected="reasonTypeSelected"
          :reason-type-options="reasonTypeOptions"
          :info-table="infoTable"
          :info-table-options="infoTableOptions"
          :info-idx="infoIdx"
          :reason-selected="reasonSelected"
          :reason-options="reasonOptions"
          :written-reason="writtenReason"
          :is-etc-reason-step="isEtcReasonStep"
          :is-campaign-step="isCampaignStep"
          :is-directly-written-reason="isDirectlyWrittenReason"
          @hidden="turnOffAddNewSnsSidebar"
          @ok="addCaution"
          @input-reason-type-selected="onInputReasonTypeSelected"
          @input-info-table="onInputInfoTable"
          @input-info-idx="onInputInfoIdx"
          @input-reason-selected="onInputReasonSelected"
          @input-written-reason="onInputWrittenReason"
        />

        <manage-caution-sidebar
          :visible="visibleManageCautionSidebar"
          :items="itemsOfManageCaution"
          :fields="fieldsOfManageCaution"
          :state-options="cautionStateOptions"
          @hidden="turnOffManageCautionSidebar"
          @change-state="onChangeCautionState"
        />

        <delete-advertiser-sidebar
          :visible="visibleDeleteAdvertiserSidebar"
          :is-saving="isSavingDeleteAdvertiser"
          :is-valid="isValidDeleteAdvertiser"
          :reason-type-selected="reasonTypeSelectedOfDeleteAdvertiser"
          :reason-type-options="reasonTypeOPtionsOfDeleteAdvertiser"
          :reason="reasonOfDeleteAdvertiser"
          @hidden="turnOffDeleteAdvertiserSidebar"
          @ok="deleteAdvertiser"
          @input-reason-type-selected="onInputReasonTypeSelectedOfDeleteAdvertiser"
          @input-reason="onInputReasonOfDeleteAdvertiser"
        />

        <business-sidebar
          v-if="businessObj"
          :visible="visibleBusinessSidebar"
          :businessObj="businessObj"
          @hidden="turnOffBusinessSidebar"
          @confirm="confirmBusiness"
          @addFile="addFile"
          @submit="submitBusiness"
        />

        <ban-modal
          :visible="visibleBanModal"
          :reason="reasonOfBan"
          @input-reason="inputReasonBan"
          @hidden="turnOffBanModal"
          @ok="ban"
        />
        <ReleaseBanModal />
        <RefundSubsModal />
        <RefundPaugModal />
        <UpdateSuggestionCountSidebar />
        <RepaySidebar />
        <CancelPayModal />
      </template>
    </TagbyDetailLayout>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import TagbyTimeline from '@/components/TagbyTimeline.vue'
import TagbyMemo from '@/components/TagbyMemo.vue'
import TagbyLoadError from '@/components/TagbyLoadError.vue'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'

import { useInitialize, useDefault } from './viewModel'
import AdvertiserInfoCard from './components/AdvertiserInfoCard.vue'
import AdvertiserSubscriptionCard from './components/AdvertiserSubscriptionCard.vue'
import AdvertiserCampaignCard from './components/AdvertiserCampaignCard.vue'
import ActionCard from './components/ActionCard.vue'
import AddCautionSidebar from './components/AddCautionSidebar.vue'
import ManageCautionSidebar from './components/ManageCautionSidebar.vue'
import DeleteAdvertiserSidebar from './components/DeleteAdvertiserSidebar.vue'
import BusinessSidebar from './components/BusinessSidebar.vue'
import BanModal from './components/BanModal.vue'
import SubscriptionTableCard from './components/SubscriptionTableCard.vue'
import PaugTableCard from './components/PaugTableCard.vue'
import BillingList from './components/BillingList.vue'
import ReleaseBanModal from './components/ReleaseBanModal.vue'
import RefundSubsModal from './components/RefundSubsModal.vue'
import RefundPaugModal from './components/RefundPaugModal.vue'
import UpdateSuggestionCountSidebar from './components/UpdateSuggestionCountSidebar.vue'
import PayList from './components/PayList.vue'
import RepaySidebar from './components/RepaySidebar.vue'
import CancelPayModal from './components/CancelPayModal.vue'
import BillingLog from './components/BillingLog.vue'

export default {
  components: {
    TagbyTimeline,
    TagbyMemo,
    TagbyLoadError,
    TagbyDetailLayout,

    AdvertiserInfoCard,
    AdvertiserSubscriptionCard,
    AdvertiserCampaignCard,
    ActionCard,
    AddCautionSidebar,
    ManageCautionSidebar,
    DeleteAdvertiserSidebar,
    BusinessSidebar,
    BanModal,
    SubscriptionTableCard,
    PaugTableCard,
    BillingList,
    ReleaseBanModal,
    RefundSubsModal,
    RefundPaugModal,
    UpdateSuggestionCountSidebar,
    PayList,
    RepaySidebar,
    CancelPayModal,
    BillingLog,
  },
  setup() {
    const { initialize } = useInitialize()
    initialize()

    const defaultData = useDefault()
    const {
      fetchDetail,
      fetchTimelines,
    } = defaultData
    fetchDetail()
    fetchTimelines()

    return {
      ...defaultData,
    }
  },
}
</script>
