<template>
  <tagby-sidebar
    :visible="visible"
    @hidden="$emit('hidden')"
  >
    <template #title>
      메모
    </template>
    <template #content>
      <div class="mb-1">
        <b-row>
          <b-col>
            <b-form-group>
              <label>내용</label>
              <b-form-textarea
                :value="content"
                rows="8"
                @input="$emit('input', $event)"
              />
              <!-- @input="test($event)" -->
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col>
            <b-button
              variant="outline-primary"
              class="mr-1"
              :disabled="!isValid"
              @click="$emit('save')"
            >
              <b-spinner
                v-if="isSaving"
                small
              />
              저장
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="$emit('hidden')"
            >
              취소
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'
import TagbySidebar from './TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    TagbySidebar,
  },
  model: {
    prop: 'content',
    event: 'input',
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    content: {
      type: String,
      default: null,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['hidden', 'input', 'save'],
  computed: {
    isValid() {
      return Boolean(this.content)
    },
  },
  methods: {
    test(value) {
      console.log(value)
    },
  },
}
</script>
